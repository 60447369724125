import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/vincepicone/Programming/Work/carbon-website/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Caption = makeShortcode("Caption");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "color"
    }}>{`Color`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Class`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Color token`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--loading__svg`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`stroke`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$ui-03`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--inline-loading__checkmark`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$interactive-01`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--inline-loading__text`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$text-01`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "typography"
    }}>{`Typography`}</h2>
    <p>{`Button text should be set in sentence case, with only the first word in a phrase and any proper nouns capitalized.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Class`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Font-size (px/rem)`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Font-weight`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type token`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--inline-loading__text`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`14 / 0.875`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Regular / 400`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$body-short-01`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "structure"
    }}>{`Structure`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Class`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`px / rem`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Spacing token`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Spinner`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`width, height`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`16 / 1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`–`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Checkmark`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`width, height`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`16 / 1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`–`}</td>
        </tr>
      </tbody>
    </table>
    <div className="image--fixed">
      <span {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1152px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "31.25%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAYAAADDl76dAAAACXBIWXMAAAsSAAALEgHS3X78AAAAgklEQVQY02NgwAF22ddgiNkoGjAwCEHY4uLiDESDi+qpYHqtXz/jfyzyJBkGN/A/xKjF0bMlG5MnyqkwMLAJKyiyqUrIMpJlaFrLPjCd3H9OyrpujhaQyS0jp8QvJi7ORLaX/1tAPXzMkpEiL4MN84cY9r8AQrOIMjNIi0vDDcNlKAB+/h7S6F4cegAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Inline loading spinner structure measurements",
          "title": "Inline loading spinner structure measurements",
          "src": "/static/eb00a33a786e159cfb033d26022ed5b0/fb070/inline-loading-style-1.png",
          "srcSet": ["/static/eb00a33a786e159cfb033d26022ed5b0/d6747/inline-loading-style-1.png 288w", "/static/eb00a33a786e159cfb033d26022ed5b0/09548/inline-loading-style-1.png 576w", "/static/eb00a33a786e159cfb033d26022ed5b0/fb070/inline-loading-style-1.png 1152w", "/static/eb00a33a786e159cfb033d26022ed5b0/c3e47/inline-loading-style-1.png 1472w"],
          "sizes": "(max-width: 1152px) 100vw, 1152px",
          "loading": "lazy"
        }}></img>{`
    `}</span>
    </div>
    <div className="image--fixed">
      <span {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1152px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "57.20108695652174%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsSAAALEgHS3X78AAABYUlEQVQoz2NgQAP///+H0xfPn2FoXvmMYcu2XQyHDx1kuHXrFsOdO3cYbt++DcYgNgiA2FgBzDBSAMxQDDBx4kQwvXLlSqbGxkaRBQsXcv/6+Y21c80jroOHj7NfvnSRDegSNqABcBqIGXEaiuxVED577TEjiHZueCSekJbHn56axLt58xamN2/eMF6/fp0RaCgjXi9ra2uDaREREQZzc3MWYr2MM/xgXp49ezZTR0eH0MaNGzm/fHrHOmPDNa558xfyLFy4gAcYKcR7GQQkJCRQggCErfJPsyckpXEnJyVy79u3j/Hp06cMN27cAHsZ5jqsrnR1dQXTzs7ODB4eHqwUx/KMGTPA9KxZs5gnTZrEf+vGDfbHr1+xnWnp4p09fTrvlKlT+fbs2cMJdCEb1OvsBL2sp6cHZ59atx5MHzK05EiKiuKIionhzMjIYAaJ7d27F57A8RoIA0JCQgzkxjIAg2/b0bRrlaUAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Inline loading states",
          "title": "Inline loading states",
          "src": "/static/6a1fd2fcaef0fa978d4c31b39c29e372/fb070/inline-loading-style-2.png",
          "srcSet": ["/static/6a1fd2fcaef0fa978d4c31b39c29e372/d6747/inline-loading-style-2.png 288w", "/static/6a1fd2fcaef0fa978d4c31b39c29e372/09548/inline-loading-style-2.png 576w", "/static/6a1fd2fcaef0fa978d4c31b39c29e372/fb070/inline-loading-style-2.png 1152w", "/static/6a1fd2fcaef0fa978d4c31b39c29e372/c3e47/inline-loading-style-2.png 1472w"],
          "sizes": "(max-width: 1152px) 100vw, 1152px",
          "loading": "lazy"
        }}></img>{`
    `}</span>
    </div>
    <div className="image--fixed">
      <span {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1152px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "33.55978260869565%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAYAAAAIy204AAAACXBIWXMAAAsSAAALEgHS3X78AAABH0lEQVQoz2NgQALH1r4F01t7PjNcOfGC8f2nN4xvXr9h/PDxI+P3Tx8Zr119wrjpwAWwml3/fzPsdihhIAi2TXhPUM2Gz98ZiAJrJ10H0wvK7rNuX3la++//71K/fvyS+vH3j+THczflr646pr6vZ40QSM2O//8ZTull4jfw7esPjCD6/6//PM+ePr/y4MGDTw8fPnz96vXrN9+/fH55/e6r/2tvPkwEqdn95CVz7dw1+A188eYRmP7//x/j3Xu35c+dPad24cIF1Rs3b6q+ef5ced/VazoR27dKg9R0nTzFwDC1h7C3/wO9Qgjc/PoHwggNw6/w6dOnYPrbt28M9+7dY7xx4wbjzZs3GYFeZ3z65Anjs6evGWEWPviN3WIAifuZ2ZhcxtYAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Inline loading spinner in context example",
          "title": "Inline loading spinner in context example",
          "src": "/static/3d5bfa6cab6e90fc206592ed5292fc9e/fb070/inline-loading-style-3.png",
          "srcSet": ["/static/3d5bfa6cab6e90fc206592ed5292fc9e/d6747/inline-loading-style-3.png 288w", "/static/3d5bfa6cab6e90fc206592ed5292fc9e/09548/inline-loading-style-3.png 576w", "/static/3d5bfa6cab6e90fc206592ed5292fc9e/fb070/inline-loading-style-3.png 1152w", "/static/3d5bfa6cab6e90fc206592ed5292fc9e/c3e47/inline-loading-style-3.png 1472w"],
          "sizes": "(max-width: 1152px) 100vw, 1152px",
          "loading": "lazy"
        }}></img>{`
    `}</span>
    </div>
    <Caption mdxType="Caption">
  Structure measurements for small and large loading spinner | px / rem
    </Caption>
    <h3 {...{
      "id": "placement"
    }}>{`Placement`}</h3>
    <p>{`The inline loading component should appear during any user action loading. If button is used to trigger the action, the inline loading component should replace that button.`}</p>
    <div className="image--fixed">
      <img {...{
        "src": "/a12da714d1543a1ebf216983682adb8d/inline-loading-animated.gif",
        "alt": "Inline loading spinner animated_in context example"
      }}></img>
    </div>
    <Caption mdxType="Caption">Example of a inline loading in product context</Caption>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      